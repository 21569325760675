
<template>
  <section>
    <section class="search-container">
      <a-form layout="inline" class="m-t-14" :form="form" @submit="submitForm">
        <a-form-item>
          <a-select default-value="入库单据编号" @change="handleChange">
            <a-select-option value="1"> 入库单据编号 </a-select-option>
            <a-select-option value="2"> 批次订单编号 </a-select-option>
            <a-select-option value="3"> 入库订单编号 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-input
            autocomplete="off"
            v-decorator="['orderNum']"
            placeholder="请输入"
          />
        </a-form-item>

        <a-form-item label="供应商">
          <a-input
            autocomplete="off"
            v-decorator="['supplier']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="仓库">
          <a-select
            style="width: 120px"
            placeholder="请选择"
            @change="handleChangeWarehouse"
            v-decorator="['warehouseId']"
          >
            <a-select-option
              v-for="item in warehouseData"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="送货人">
          <a-input
            autocomplete="off"
            v-decorator="['deliveryman']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="经办人">
          <a-input
            autocomplete="off"
            v-decorator="['operator']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="入库时间">
          <a-range-picker style="width: 224px" v-decorator="['time']">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-form-item>
        <!-- <a-form-item label="材料名称">
          <a-input placeholder="请输入" />
        </a-form-item> -->
        <a-form-item>
          <a-button type="primary" class="m-r-8" html-type="submit"
            ><a-icon type="search" />查询</a-button
          >
          <a-button @click="reset"><a-icon type="reload" />重置</a-button>
        </a-form-item>
      </a-form>
      <a-row class="right-action-panel m-t-14">
        <a-button
          class="item"
          @click="exprotFile('materialInStorageExport', { params })"
          ><a-icon type="export" />导出</a-button
        >
        <router-link to="/mms/inout/warehouseIn/addWareHouseIn">
          <a-button type="primary" class="item">新增入库</a-button>
        </router-link>
      </a-row>
    </section>

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="list.data"
        size="small"
        class="m-t-18"
        @change="changePageNum"
        :pagination="{
          showQuickJumper: false,
          current: list.pageNum,
          total: list.total,
        }"
        :columns="list.columns"
      >
        <template slot="operation" slot-scope="list, text">
          <router-link
            :to="'/mms/inout/warehouseIn/warehouseInDetail?id=' + text.id"
          >
            <a style="color: #529aff">查看</a>
          </router-link>
          <!-- <a style="color: #529aff">查看</a> -->
          <!-- 
          <a-divider style="color:#222" type="vertical" />
          <a style="color:#529aff">删除</a> -->
        </template>
      </a-table>
    </section>
  </section>
</template>

<script>
import { exprotFile } from "@/until/unit";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      orderType: "1", //订单类型
      startTime: "",
      params: {},
      endTime: "",
      warehouseId: "",
      batchCode: "",
      code: "",
      deliveryman: "",
      operator: "",
      orderCode: "",
      supplier: "",
      warehouseData: [], //仓库下拉列表
      list: {
        pageNum: 1,
        total: 0,
        data: [],
        columns: [
          { title: "序号", dataIndex: "ind", align: "center" ,ellipsis: true, },
          { title: "入库单据编号", dataIndex: "orderCode", align: "center",ellipsis: true,  },
          { title: "批次订单编号", dataIndex: "batchCode", align: "center",ellipsis: true,  },
          { title: "供货商", dataIndex: "supplier", align: "center" ,ellipsis: true,  },
          // { title: "材料名称", dataIndex: "ind4", align: "center" },
          // { title: "计量单位", dataIndex: "ind5", align: "center" },
          //{ title: "入库数量", dataIndex: "ind6", align: "center" },
          //{ title: "材料金额", dataIndex: "ind7", align: "center" },
          { title: "入库订单编号", dataIndex: "code", align: "center",ellipsis: true,  },
          { title: "送货人", dataIndex: "deliveryman", align: "center",ellipsis: true,  },
          { title: "经办人", dataIndex: "operator", align: "center" ,ellipsis: true, },
          { title: "入库日期", dataIndex: "inTime", align: "center" ,ellipsis: true, },
          {
            title: "操作",
            dataIndex: "operation",
            align: "center",
            scopedSlots: { customRender: "operation" },
          },
        ],
      },
    };
  },
  created() {
    this.warehouse(); //仓库下拉别表
    this.getInStorageList(); //入库记录列表
  },
  methods: {
    exprotFile,
    // 选择编号类型
    handleChange(type) {
      this.orderType = type;
    },
    // 仓库下拉列表
    warehouse() {
      this.$api.warehouseData().then((res) => {
        if (res.code == "200") {
          this.warehouseData = res.data ? res.data : [];
        }
      });
    },
    // 获取仓库id
    handleChangeWarehouse(val) {
      this.warehouseId = val;
    },
    // 查询
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.form.getFieldsValue();
      this.startTime = formVal.time ? formVal.time[0].format("YYYY-MM-DD") : "";
      this.endTime = formVal.time ? formVal.time[1].format("YYYY-MM-DD") : "";
      this.orderCode = this.orderType == "1" ? formVal.orderNum : "";
      this.batchCode = this.orderType == "2" ? formVal.orderNum : "";
      this.code = this.orderType == "3" ? formVal.orderNum : "";
      this.warehouseId = formVal.warehouseId;
      this.deliveryman = formVal.deliveryman;
      this.list.pageNum = 1;
      this.operator = formVal.operator;
      this.supplier = formVal.supplier;
      this.current = this.list.pageNum;

      this.getInStorageList();
    },
    // 重置
    reset() {
      this.startTime = "";
      this.endTime = "";
      this.list.pageNum = 1;
      this.orderCode = "";
      this.batchCode = "";
      this.warehouseId = "";
      this.deliveryman = "";
      this.operator = "";
      this.supplier = "";
      this.code = "";
      this.list.pageNum = 1;
      this.form.resetFields();
      this.getInStorageList();
    },

    // 分页
    changePageNum(p) {
      this.list.pageNum = p.current;
      this.getInStorageList();
    },
    // 查询列表
    getInStorageList() {
      this.params = {
        batchCode: this.batchCode,
        current: this.list.pageNum,
        deliveryman: this.deliveryman,
        startTime: this.startTime,
        endTime: this.endTime,
        warehouseId: this.warehouseId,
        operator: this.operator,
        orderCode: this.orderCode,
        supplier: this.supplier,
        code: this.code,
      };
      this.$api.getInStorageList(this.params).then((res) => {
        if (res.code == "200") {
          this.list.total = res.data.total;
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach((item, index) => {
              item.ind = index + 1;
              item.key = index + 1;

              item.inTime = item.inTime.split(" ")[0]; //特殊处理日期
            });
          }
          this.list.data = res.data.records;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100% !important;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
</style>